// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qcRCmMGm3KTu6bvtujD1{display:block}.qcRCmMGm3KTu6bvtujD1 .jKT40WL8hC4pbSNP_f3P{padding:20px 0 0 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "qcRCmMGm3KTu6bvtujD1",
	"searchResultNote": "jKT40WL8hC4pbSNP_f3P"
};
export default ___CSS_LOADER_EXPORT___;
